import React from 'react';

function Tab({ label, activeTab, onClick, isActive }) {
  return (
    <li
      className={`tab-list-item ${isActive ? 'active' : ''}`}
      onClick={() => onClick(label)}
    >
      {label}
    </li>
  );
}

export default Tab;