import React from 'react';

function ResumeTab() {
  return (
    <div className="resume">
      <div className="resume-header">
        <h1>Cleo Spiers</h1>
        <p>contact@cleospiers.com</p>
      </div>
      <hr />
      <div className="resume-section">
        <h2>Objective</h2>
        <p>
          To destroy language
        </p>
      </div>
      <hr />
      <div className="resume-section">
        <h2>Education</h2>
        <ul>
          <li>Bachelor of Science in Computer Science, University of Oklahoma, Norman OK</li>
        </ul>
      </div>
      <hr />
      <div className="resume-section">
        <h2>Skills</h2>
        <ul>
          <li>Programming languages: Java, JavaScript, Python, C++</li>
          <li>Web development frameworks: React, Angular, Vue</li>
          <li>Database management: MySQL, MongoDB</li>
          <li>Version control: Git, SVN</li>
          <li>Operating systems: Windows, macOS, Linux</li>
          <li>Cloud Platforms: AWS, Azure</li>
        </ul>
      </div>
      <hr />
      <div className="resume-section">
        <h2>Experience</h2>
        <h3>SWE, Faang</h3>
        <h3>Seattle, WA</h3>
        <p><em>June 2022 - Present</em></p>
        <h3>SWE, Small blood bank</h3>
        <h3>Oklahoma City, OK</h3>
        <p><em>May 2021 - May 2022</em></p>
      </div>
    </div>
  );
}

export default ResumeTab;