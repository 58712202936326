import logo from './me.svg';
import './App.css';
import React from 'react';
import Tabs from './Components/Tabs';
import ResumeTab from './Components/ResumeTab';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Tabs>
        <div label="Home" className="Test">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
             Work in progress.
          </p>
          <a
            className="App-link"
            href="https://twitter.com/guattar1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Go to my twitter
         </a>
        </div>
          <div label="Resume">
            <p><ResumeTab></ResumeTab></p>
          </div>
          <div label="Run">
            <h2>WIP</h2>
            <p>Come back whenever I remember to finish this.</p>
          </div>
          <div label="Writings">
            <h2>WIP</h2>
            <p>Come back whenever I remember to finish this.</p>
          </div>
        </Tabs>
      </header>
    </div>
  );
}

export default App;
