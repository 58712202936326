import React, { useState } from 'react';
import Tab from './Tab';

function Tabs({ children }) {
  const [activeTab, setActiveTab] = useState(children[0].props.label);

  const onClickTab = (tab) => setActiveTab(tab);

  return (
    <div className="tabs">
      <ul className="tab-list">
        {children.map((child) => {
          const { label } = child.props;
          const isActive = activeTab === label;
          return (
            <Tab
              key={label}
              activeTab={activeTab}
              label={label}
              onClick={onClickTab}
              isActive={isActive}
            />
          );
        })}
      </ul>
      <div className="tab-content">
        {children.map((child) => {
          if (child.props.label !== activeTab) return null;
          return child.props.children;
        })}
      </div>
    </div>
  );
}

export default Tabs;